<template>
    <div class="w-full flex my-10">
        <div class="w-full text-center">
            <img class="mx-auto " src="/imgs/logo_small.png" alt="Spirit4 Brand logo">
			<div class="text-xl text-primary mb-10">Universal Connector</div>

            <div v-if="isConnected">
                <div class="font-secondary text-2xl">> Connected as <span class="text-gray-400">{{formattedWallet}}</span></div>

                <div v-if="isBalanceLoaded" class="font-secondary text-2xl">> You have <span class="text-gray-400">{{balance}} spirits</span></div>

                <div v-if="isBalanceLoaded && isHolder" class="font-secondary text-2xl">> Access <span class="text-green-400">guaranteed!</span></div>
                <div v-if="isBalanceLoaded && !isHolder" class="font-secondary text-2xl">> Access <span class="text-red-400">denied!</span></div>
            </div>


            <div class="my-12">
                <div v-if="fail">
                    <div class="font-secondary text-2xl font-bold">Syncing <span class="text-red-400">failed!</span></div>
                    <div class="font-secondary text-2xl font-bold">Refresh the page to try again</div>
                </div>
    
                 <div v-if="isHolder">
                    <div class="font-secondary text-2xl font-bold">This page will close in {{seconds}}...</div>
                </div>

                <div v-if="isBalanceLoaded && !isHolder">
                    <div class="font-secondary text-2xl font-bold">You must have at least 1 Spirit to <br>use the Spirit4 Universal Connector</div>

					<a href="https://opensea.io/collection/s4base" target="_blank" class="inline-block mx-auto button">
						<img src="/imgs/BUY_HERE.png" alt="Buy here">
					</a>

                </div>
            </div>


            <div v-if="hasLoadingMessage && !fail" class="mt-12">
                <div class="font-secondary text-2xl">{{loadingMessage}}</div>
                <img class="mx-auto" src="/imgs/loading2.gif" alt="Loading gif">
            </div>

        </div>
    </div>
</template>

<script>
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

const Web3 = require('web3')
const S4 = require('../scripts/contract')

const CHAIN_ID = process.env.VUE_APP_CHAIN_ID
const CHAIN_NAME = process.env.VUE_APP_CHAIN_NAME
const INFURA_ID = process.env.VUE_APP_INFURA_ID


const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider,
        options: {
            infuraId: INFURA_ID
        }
    },
    coinbasewallet: {
        package: CoinbaseWalletSDK, 
        options: {
            appName: "Spirit4", 
            infuraId: INFURA_ID,
            chainId: CHAIN_ID, 
            darkMode: false
        }
    },
    opera: {
        package: true
    }
};

const web3Modal = new Web3Modal({
  network: CHAIN_NAME, 
  cacheProvider: true,
  providerOptions
});


export default {
	data: function(){
		return{
            wallet: null,
            fail: false,
            loadingMessage: null,
            balance: null,
            seconds: 3
        }
	},
	computed:{
		formattedWallet: function(){
			if (!this.isConnected) return ""
			return `${this.wallet.substr(0, 6)}...${this.wallet.substr(-4, 4)}`
		},
        isConnected: function(){
            return this.wallet != null && this.wallet != undefined
        },
        hasLoadingMessage: function(){
            return this.loadingMessage != null && this.loadingMessage != undefined
        },
        isHolder: function(){
            return this.isBalanceLoaded && this.balance > 0
        },
        isBalanceLoaded: function(){
            return this.balance != null && this.balance != undefined && !isNaN(this.balance)
        }
	},
	methods: {

        switchChain: async function (provider){
            return new Promise((resolve, reject) => {

                const web3 = new Web3(provider)
                web3.eth.getChainId()
                .then(chainId => {
                    if (chainId == CHAIN_ID) resolve(chainId)
                    else{
                    provider.request({	method: "wallet_switchEthereumChain", params: [{chainId: CHAIN_ID}]})
                        .then(res => resolve(res))
                        .catch(error => reject(error))
                    }
                })
                .catch(error => reject(error))

            })
        },

        getTokens: function(){
            this.loadingMessage = 'Checking spirits...'

            S4.balanceOf(this.wallet).then(balance => {
                this.balance = parseInt(balance)
                this.loadingMessage = null
                this.seconds = 3
                
                var broadcaster = new BroadcastChannel('spirit4');
                broadcaster.postMessage({
                    wallet: this.wallet,
                    balance: this.balance
                });
                broadcaster.close()

            }).catch(error => this.fail = true)
        },

		connectWallet: function(){
            this.loadingMessage = 'Syncing wallet...'

            web3Modal.clearCachedProvider()
            web3Modal.connect()
                .then(provider => {

                this.switchChain(provider)
                    .then(res => {

                        const web3 = new Web3(provider)
                        web3.eth.getAccounts()
                            .then(wallets => {
                                S4.setProvider(provider)

                                this.wallet = wallets[0]
                                this.getTokens()
                            })
                            .catch(error => this.fail = true)
                    })
                    .catch(error => this.fail = true)
                })
                .catch(error => this.fail = true)
		},
	},
	mounted: async function(){
        this.connectWallet()

        setInterval(() => {
            this.seconds--
        }, 1000);
	}
}
</script>
