<template>
  <div v-on:click="mobileMenu = false" v-if="mobileMenu" class="fixed top-0 left-0 bottom-0 right-0 bg-primary md:hidden bg-opacity-20"></div>
  <div class="navbar w-full bg-black fixed top-0">
      <div class="max-w-5xl text-center mx-auto flex h-20 px-5">
        <a class="my-auto" v-on:click="mobileMenu = false" href="#">
          <img src="/imgs/logo_small.png">
        </a>
        <div class="hidden md:flex flex-grow justify-end menu">
            <a href="#collection" class="my-auto navbar-item">Collection</a>
            <a href="#lore" class="my-auto navbar-item">Lore</a>
            <a href="#details" class="my-auto navbar-item">Details</a>
            <a href="#roadmap" class="my-auto navbar-item">Roadmap</a>
            <a v-if="mintingOpen" href="#mint" class="my-auto navbar-item text-primary">Mint</a>
            
            <a :href="`${twitterLink}`" target="_blank" class="my-auto"><img src="/imgs/icon_navbar_socials_twitter.png"></a>
            <a :href="`${openseaLink}`" target="_blank" class="my-auto"><img src="/imgs/icon_navbar_socials_opensea.png"></a>
        </div>
        <div class="flex md:hidden flex-grow justify-end">
          <a v-on:click="mobileMenu = !mobileMenu" class="my-auto cursor-pointer">
            <img src="/imgs/icon_navbar_menu.png" alt="">
          </a>
        </div>
      </div>

      <div class="mobile-menu md:hidden" :class="{ 'mobile-menu-visible': mobileMenu }">
        <div class="py-5 border-t-4  border-primary border-double">
          <div class="links">
            <a v-on:click="mobileMenu = false" href="#collection">Collection</a>
            <a v-on:click="mobileMenu = false" href="#lore">Lore</a>
            <a v-on:click="mobileMenu = false" href="#details">Details</a>
            <a v-on:click="mobileMenu = false" href="#roadmap">Roadmap</a>
            <a v-if="mintingOpen" v-on:click="mobileMenu = false" href="#mint" class="text-primary">Mint</a>
          </div>
          
          <div class="flex justify-center gap-3 mt-5">
            <a :href="`${twitterLink}`" target="_blank"><img src="/imgs/icon_navbar_socials_twitter.png"></a>
            <a :href="`${openseaLink}`" target="_blank"><img src="/imgs/icon_navbar_socials_opensea.png"></a>
          </div>

        </div>
      </div>
  </div>
</template>

<script>
const OPENSEA_LINK = process.env.VUE_APP_OPENSEA_LINK
const TWITTER_LINK = process.env.VUE_APP_TWITTER_LINK
const DISCORD_LINK = process.env.VUE_APP_DISCORD_LINK 

export default {
  name: 'NavBar',
  props: {
    mintingOpen: Boolean
  },
  data(){
    return {
      openseaLink: OPENSEA_LINK,
			twitterLink: TWITTER_LINK,
      discordLink: DISCORD_LINK,

      mobileMenu: false
    }
  }
}
</script>


<style scoped>
    .navbar-item{
        @apply text-xl mx-3;
    }
    .navbar .menu img{
        @apply ml-3
    }
    .mobile-menu{
      @apply bg-black overflow-hidden max-h-0 transition-all;
    }
    .mobile-menu-visible{
      @apply max-h-96;
    }
    .mobile-menu .links a{
      @apply block text-center text-2xl mb-3;
    }
    
</style>
