<template>
  <div>

    <NavBar :mintingOpen="!isPaused && !isSoldOut"/>

    <div class="bg-up mt-20 3xl:bg-up-stretch md:bg-size-auto">
      <div class="max-w-5xl mx-auto px-5">
        <img class="mx-auto px-10" src="/imgs/spirit4_brandimage.gif" alt="Spirit4 brand image">
        <img class="mx-auto md:-my-7" src="/imgs/logo_big.png" alt="Spirit4 logo">
        
        <div class="flex justify-center my-3">
          <img class="my-auto" src="/imgs/asset_ornament_white_left.png">
          <div class="my-auto mx-3 text-xl md:text-2xl">10K PFP NFT COLLECTION</div>
          <img class="my-auto" src="/imgs/asset_ornament_white_right.png">
        </div>

        <div v-if="!loading">

          <div v-if="isPaused && isAllowlistEnabled" class="text-center my-20">
            <div class="text-white font-secondary text-2xl md:text-4xl"><span class="text-primary text-2xl md:text-4xl">Holders</span> minting in</div>

            <div v-if="!zeroCountdown" class="font-secondary font-bold text-3xl md:text-5xl text-primary my-8">{{publicMintingCountdown}}</div>
            <div v-if="zeroCountdown" class="font-secondary font-bold text-3xl md:text-5xl text-primary my-8">Now! Refresh the page!</div>

            <div class="text-base md:text-xl text-gray-500">{{mintingDateText}}</div>
            <div class="text-base md:text-xl text-gray-500">{{mintingDateGMT}}</div>
          </div>

          <div v-if="!isPaused && !isSoldOut">
            <a href="#mint">
              <button class="mx-auto block my-12">
                <img src="/imgs/mint-open.png" alt="Mint open">
              </button>
            </a>
          </div>
          <div v-if="isSoldOut">
            <a :href="`${openseaLink}`" target="_blank">
              <img class="mx-auto my-12 px-10" src="/imgs/bt_soldout.jpg" alt="Soldout">
            </a>
          </div>
        </div>


        <img class="mx-auto mt-10" src="/imgs/icon_ethereum.png" alt="Ethereum icon">
      </div>


    </div>

    <div class="bg-middle md:bg-size-auto 3xl:bg-middle-stretch bg-white text-black -mt-10 md:-mt-20 pt-48 md:pt-96">
      <div class="mx-auto mt-10 max-w-5xl md:px-5">
        <img class="hidden md:block" src="/imgs/asset_four_races_desktop.jpg" alt="Four races against the Anunnakis">
        <img class="block md:hidden" src="/imgs/mobile/asset_four_races_mobile.jpg" alt="Four races against the Anunnakis">
      </div>
    </div>

    <div class="bg-down bg-white 3xl:bg-down-stretch text-white pt-20">
      <div class="anchor" id="collection"></div>

      <div class="mx-auto max-w-5xl px-20 md:px-5">
        <img class="mx-auto" src="/imgs/title_the_collection.png" alt="The collection">

        <img class="mx-auto hidden md:block" src="/imgs/asset_the_collection_highlights_desktop.png" alt="4 NFTs 1 of 1, hundreds of traits, random generated">
        <img class="mx-auto block md:hidden" src="/imgs/mobile/asset_the_collection_highlights_mobile.jpg" alt="4 NFTs 1 of 1, hundreds of traits, random generated">
      </div>
        
      <div class="grid grid-cols-2 md:grid-cols-4 max-w-lg md:max-w-5xl mx-auto text-center mt-20 gap-x-4 md:gap-x-9 px-4 gap-y-14">
        <div>
          <img class="mx-auto" src="/imgs/icon_races_human.png" alt="Human race icon">
          <div class="race-name">HUMANS</div>
          <div class="race-class">Combatant</div>
          <img class="w-full mt-2" src="/imgs/Spirit_Human.jpg" alt="Spirit4 Human">
        </div>
        <div>
          <img class="mx-auto" src="/imgs/icon_races_igigi.png" alt="Igigi race icon">
          <div class="race-name">IGIGIS</div>
          <div class="race-class">Spellcaster</div>
          <img class="w-full mt-2" src="/imgs/Spirit_Igigi.jpg" alt="Spirit4 Igigi">
          <div class="text-primary text-center mt-2 text-xs">Branding reserved</div>
        </div>
        <div>
          <img class="mx-auto" src="/imgs/icon_races_akalis.png" alt="Akalis race icon">
          <div class="race-name">AKALIs</div>
          <div class="race-class">Protector</div>
          <img class="w-full mt-2" src="/imgs/Spirit_Akalis.jpg" alt="Spirit4 Akalis">
        </div>
        <div>
          <img class="mx-auto" src="/imgs/icon_races_lazuli.png" alt="Lazuli race icon">
          <div class="race-name">LAZULIS</div>
          <div class="race-class">Technomancer</div>
          <img class="w-full mt-2" src="/imgs/Spirit_Lazuli.jpg" alt="Spirit4 Lazuli">
        </div>
      </div>

      <img class="mx-auto my-14" src="/imgs/asset_ornament_separator.png">

      <div class="max-w-3xl mx-auto text-center text-xl md:text-2xl px-5">
        <p>The collection has 4 unique races, Humans, Akalis, Igigi and Lazuli; each race has its own rarity, humans are the most common and lazulis are the rarest, there are several racial combinations.</p>
        <br>
        <p class="pb-36">There are 4 unique spirits between the 10.000 NFTs collection, 1 spirit for each race, it's an exclusive artwork. Learn more about races on our Discord under the channel #lore. There will be a minimum amount of pure race NFTs.</p>
      </div>
    </div>


    <div class="max-w-5xl mx-auto grid grid-cols-3 md:grid-cols-6 gap-4 -mt-14 md:-mt-10 px-4">
      <div v-for="index in randomizedNfts.length" :key="index" :class="{'hidden md:block':index > mobileNFts}">
		<div class="cursor-pointer" v-on:click="showImage(index-1)">
        	<img class="w-full" :src="`/imgs/nfts/thumbnail/${randomizedNfts[index-1]+1}.png`" alt="Random generated Spirit4 NFT">
		</div>
      </div>
    </div>

    <div class="mt-32 max-w-5xl mx-auto px-5">
      <div class="flex justify-center">
        <img class="mr-4" src="/imgs/asset_opensource_left.png">
        <div class="my-auto text-xl md:text-2xl">OPEN SOURCE GAMING <br class="md:hidden"> METADATA EMBEDDED</div>
        <img src="/imgs/asset_opensource_right.png">
      </div>

      <div class="block md:grid grid-cols-2 mt-8 metadata">
        <div>
          <div class="md:pr-20">
            <img class="px-5 w-full max-w-sm mx-auto md:mr-0 md:ml-auto md:w-auto md:max-w-full md:px-0 rounded-2xl" src="/imgs/asset_opensource_nft_display.jpg" alt="Random generated Spirit4 NFT">
          </div>
          <div class="block md:hidden text-xl text-center mt-3">TOKEN #????</div>
        </div>
        <div>
          <div class="hidden md:flex -ml-32 mt-14">
            <div class="my-auto mr-5">
              <img src="/imgs/asset_opensource_connector.png" >
            </div>
            <div class="text-2xl">TOKEN #????</div>
          </div>

          <div class="mt-12 text-xl md:text-2xl">
            <img class="mb-5 mx-auto md:mx-0" src="/imgs/asset_opensource_title_traits.png" alt="Traits">
            <table class="leading-6 md:leading-7 w-full md:w-auto">
              <tr>
                <td>RACE</td>
                <td>HUMAN</td>
              </tr>
              <tr>
                <td>GEN</td>
                <td>1</td>
              </tr>
              <tr>
                <td>BG</td>
                <td>IG. RED</td>
              </tr>
              <tr>
                <td>CLASS</td>
                <td>HU. DEMOLISHER</td>
              </tr>
              <tr>
                <td>TATTOO</td>
                <td>AK. FEARLESS</td>
              </tr>
              <tr>
                <td>EYE</td>
                <td>HU. EVIL YELLOW</td>
              </tr>
              <tr>
                <td>NOSE</td>
                <td>IG. RED</td>
              </tr>
              <tr>
                <td>CLOTHING    </td>
                <td>HU. MARS COSMIC ASTRONAUT</td>
              </tr>
              <tr>
                <td>HAIR    </td>
                <td>AK. RED RIOT</td>
              </tr>
              <tr>
                <td>MOUTH    </td>
                <td>HU. HIGHLANDER</td>
              </tr>
            </table>
          </div>

          <div class="mt-12 text-xl md:text-2xl">
            <img class="mb-5 mx-auto md:mx-0" src="/imgs/asset_opensource_title_stats.png" alt="Stats">
            <table class="leading-6 md:leading-7 w-full md:w-auto">
              <tr>
                <td>MOVEMENT    </td>
                <td>SPRINT</td>
              </tr>
              <tr>
                <td>ACTIVE    </td>
                <td>BLITZ</td>
              </tr>
              <tr>
                <td>PASSIVE    </td>
                <td> DOUBLE DAMAGE</td>
              </tr>
              <tr>
                <td>ULTIMATE        </td>
                <td>SPIRIT STRIKE</td>
              </tr>
              <tr>
                <td>ATTACK        </td>
                <td>130</td>
              </tr>
              <tr>
                <td>DEFENSE        </td>
                <td>110</td>
              </tr>
              <tr>
                <td>SPIRIT POWER        </td>
                <td>110</td>
              </tr>
              <tr>
                <td>ENERGY POWER    </td>
                <td>115</td>
              </tr>
              <tr>
                <td>HP    </td>
                <td>1100</td>
              </tr>
            </table>
          </div>
          
        </div>
      </div>

    </div>

    <div class="mt-28 md:mt-52 max-w-5xl mx-auto text-center px-5">
      <div class="anchor" id="lore"></div>
      <img class="mx-auto" src="/imgs/title_lore.png" alt="Lore">

      <p class="text-xl md:text-2xl mt-20">
        On a distant planet called Kiur, there are four different species of beings that work together to wage war and defend the planet against a powerful intergalactic race called The Anunnakis.
<br>
<br>
The story begins when Anunnakis' home planet Nibiru was suffering from severe damage in its atmosphere, which led them to seek a precious mineral across the galaxy to save it from collapsing. After a long voyage across the universe, it was on Earth where they finally found this mineral, known as gold.
<br>
<br>

To fulfill its goal, the Anunnakis brought another race called Igigi as slaves to Earth, with the only purpuse to mine gold. Few hundred years later, Igigis rebelled in a violent mayhem. The Anunnakis reprehend the rebellion exterminating all of them. 
<br>
<br>

After the slaughter, the Anunnakis wondered about the idea to create a new species of beings to do their bidding, and thus they gave mankind birth. For a long time, they had humans mining gold until they achieved their objective, leaving Earth and returning to Nibiru. As a cruel race, upon leaving, the Anunnakis wanted to wipe out their creation, but only managed to kill some of those who lived on the land. 
<br>
<br>

As millennia passed, humanity was already unsure of its origin as so many different cultural myths had been created.
It was through a mission deployed to the Moon that the true story was revealed. There, they found an unknown species of beings called Lazulis, that explained everything and invited humans to fight alongside each other against the Anunnakis.
Humans then traveled to Kiur using only their consciousness, with the promise of having human DNAd bodies to possess on arrival. A few human consciences suffered a mutation while trespassing a black hole, which also carried through the DNA… a new race was created then, the Akalis.
<br>
<br>

On arrival in Kiur, Humans found out everything about Igigis and that they also have been saved and brought by Lazulis to habit the planet.
Thousands of years passed in Kiur and to better prepare for the battle ahead, the 4 races began breeding among each other, in a tentative effort to improve and mix their strengths, that's where many new hybrid beings were born, with various traits of their genitors. Among them, four mystical beings, as powerful as their pure races could be, were given birth, the Spirit4.
<br>
<br>

Humans, Akalis, Igigis and Lazulis, together with the manifestation of the will of the Spirits4 now have the full range of their powers, and are the ultimate defense against the Anunnakis.
      </p>
    </div>

    <img class="mx-auto my-20 px-5" src="/imgs/asset_ornament_separator.png">

    <div class="max-w-5xl mx-auto">
      <div class="anchor" id="details"></div>
      <img class="mx-auto mb-20 px-5" src="/imgs/title_nitty_gritty.png" alt="Nitty Gritty">
      <img class="hidden md:block mx-auto" src="/imgs/nitty-gritty_desktop_v2.jpg" alt="Spirit4 Nitty Gritty">
      <img class="block md:hidden mx-auto" src="/imgs/mobile/nitty-gritty_mobile_v2.jpg" alt="Spirit4 Nitty Gritty">
    </div>

    <div class="max-w-5xl mx-auto mt-28 md:mt-52">
      <div class="anchor" id="roadmap"></div>
      <img class="mb-20 mx-auto px-5" src="/imgs/title_roadmap.png" alt="Roadmap">
      <img class="mx-auto" src="/imgs/roadmap_img.jpg" alt="Spirit4 roadmap">
    </div>

    <div v-if="!loading" class="mt-28 md:mt-52 max-w-5xl px-5 mx-auto">
      <div class="anchor" id="mint"></div>
      <!-- <img v-if="isPaused" class="mx-auto" src="/imgs/asset_title_free_mint_soon.jpg" alt="Free mint soon"> -->
      <div v-if="!isPaused">
        <img class="mx-auto" src="/imgs/asset_title_free_mint_now.jpg" alt="Free mint now">

        <div v-if="isAllowlistEnabled">
          <div class="text-center font-secondary text-3xl md:text-5xl mt-10 line-through">Allowlist Minting</div>
          <div class="text-center font-secondary text-3xl md:text-5xl line-through">Public Minting</div>
          <div class="text-center font-secondary text-3xl md:text-5xl">Holders Minting</div>
        </div>
        <div v-if="!isAllowlistEnabled">
          <div class="text-center font-secondary text-3xl md:text-5xl mt-10 line-through">Allowlist Minting</div>
          <div class="text-center font-secondary text-3xl md:text-5xl">Public Minting</div>
          <div class="text-center font-secondary font-bold text-xl md:text-2xl text-primary">{{publicMintingCountdown}}</div>
        </div>

        <iframe v-if="isConnected" class="mx-auto my-10" width="315" height="560" src="https://www.youtube.com/embed/1eiWzfKz8cc" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

        <div class="mt-16">
          <div v-if="isConnected || isSoldOut">
            <div v-if="!isSoldOut || (isSoldOut && user.tokens.length > 0)" class="text-center mt-5 text-primary text-xl md:text-2xl">Connected as <span class="lowercase text-white ml-2">{{formattedWallet}}</span></div>
            <div v-if="!isSoldOut && !checkingAllowlist && (!isAllowlistEnabled || (isAllowlistEnabled && isWhitelisted))" class="text-center">You can mint up to <div class="inline text-2xl border-b-4 border-primary">{{userMintRemaining}}</div> Spirits</div>
            
            <div v-if="user.tokens.length > 0" class="text-center my-10">
              <div v-for="token in user.tokens" :key="token" class="text-lg md:text-xl my-2">
                ∇ You minted <a :href="`https://opensea.io/assets/ethereum/${contractAddress}/${token}`" target="_blank" class="text-primary border-b border-primary hover:opacity-80">Spirit4 #{{token}}</a> ∇
              </div>
            </div>

            <div v-if="canMint && !checkingAllowlist"> <!-- Mint buttons  -->
              <div v-if="processingTx" class="text-center my-10 border-primary border-t border-b p-10">
                <div class="text-xl md:text-2xl">{{mintingText}}</div>
                <img class="mx-auto" src="/imgs/loading2.gif" alt="Minting...">
              </div>

              <div v-if="!processingTx" class="flex justify-center gap-3 md:gap-6 mt-10">

                <button class="large-scale" v-on:click="decrease(10)">
                  <img src="/imgs/bt_arrow_10_left.png" alt="Increase 10 NFT">
                </button>
                <button class="large-scale" v-on:click="decrease(1)">
                  <img src="/imgs/bt_arrow_1_left.png" alt="Increase 1 NFT">
                </button>


                <button class="relative" v-on:click="mint()">
                  <img src="/imgs/mint_quantity_box.png" alt="Mint">
                  <div class="absolute w-full h-full top-0 flex items-center justify-center">
                    <div class="text-3xl md:text-5xl uppercase pb-2 font-secondary">Mint {{mintQuantity}}</div>
                  </div>
                </button>


                <button class="large-scale" v-on:click="increase(1)">
                  <img src="/imgs/bt_arrow_1_right.png" alt="Decrease 1 NFT">
                </button>
                <button class="large-scale" v-on:click="increase(10)">
                  <img src="/imgs/bt_arrow_10_right.png" alt="Decrease 10 NFT">
                </button>

              </div>			
            </div>

            <div v-if="isAllowlistEnabled && !isWhitelisted && !isSoldOut && !checkingAllowlist"> <!-- Not allowlisted -->
                <div class="text-primary text-2xl md:text-3xl text-center my-10">your wallet didn't make it on the snapshot</div>
            </div>

            <div v-if="checkingAllowlist"> <!-- Checking allowlist -->
                <div class="text-white text-3xl md:text-4xl text-center my-10">Checking snapshot...</div>
            </div>

            <div v-if="isSoldOut"> <!-- Sold out -->
              <img class="mt-10 mx-auto max-w-full px-10 md:max-w-lg" src="/imgs/soldout.png" alt="Soldout">
            </div>

          </div>
          <div v-else>
            <button v-on:click="connectWallet()" class="mx-auto block">
              <img src="/imgs/bt_connect.png" alt="Connect wallet">
            </button>
          </div>
        </div>
      
      <div class="text-center text-xl md:text-2xl mt-5">{{(parseInt(totalSupply)).toLocaleString('en')}} / {{(parseInt(maxSupply)).toLocaleString('en')}} minted</div>

      </div>
    </div>


    <div class="mt-52 flex justify-center gap-5 px-5">
      <a :href="`${twitterLink}`" target="_blank"><img class="w-14" src="/imgs/icon_navbar_socials_twitter.png"></a>
      <a :href="`${openseaLink}`" target="_blank"><img class="w-14" src="/imgs/icon_navbar_socials_opensea.png"></a>
    </div>

    <img class="mx-auto mt-16 w-44" src="/imgs/logo_small.png" alt="Spirit4 logo">

    <div class="footer mt-12 md:mt-24 md:footer-desktop"></div>

  </div>
</template>

<script>
// import Web3Modal from "web3modal";
// import WalletConnectProvider from "@walletconnect/web3-provider";
// import CoinbaseWalletSDK from '@coinbase/wallet-sdk';

// const Web3 = require('web3')
// const axios = require('axios');
// const S4 = require('../scripts/contract')

const MAX_SUPPLY = process.env.VUE_APP_MAX_SUPPLY
const CHAIN_ID = process.env.VUE_APP_CHAIN_ID
const CHAIN_NAME = process.env.VUE_APP_CHAIN_NAME
const CONTRACT_ADDRESS = process.env.VUE_APP_CONTRACT_ADDRESS

const INFURA_ID = process.env.VUE_APP_INFURA_ID
const INFURA_ENDPOINT = `https://${CHAIN_NAME}.infura.io/v3/${INFURA_ID}`
const WHITELIST_ENDPOINT = process.env.VUE_APP_WHITELIST_ENDPOINT

const MINTING_DATE = process.env.VUE_APP_MINTING_DATE

const OPENSEA_LINK = process.env.VUE_APP_OPENSEA_LINK
const TWITTER_LINK = process.env.VUE_APP_TWITTER_LINK
const DISCORD_LINK = process.env.VUE_APP_DISCORD_LINK 

// const providerOptions = {
//     walletconnect: {
//         package: WalletConnectProvider,
//         options: {
//             infuraId: INFURA_ID
//         }
//     },
//     coinbasewallet: {
//         package: CoinbaseWalletSDK, 
//         options: {
//             appName: "Spirit4", 
//             infuraId: INFURA_ID,
//             chainId: CHAIN_ID, 
//             darkMode: false
//         }
//     },
//     opera: {
//         package: true
//     }
// };

// const web3Modal = new Web3Modal({
//   network: CHAIN_NAME, 
//   cacheProvider: true,
//   providerOptions
// });

// let infuraProvider = new Web3.providers.HttpProvider(INFURA_ENDPOINT)


import NavBar from '../components/NavBar.vue'
export default {
	name: 'Home',
	components: {
		NavBar
	},
	data: function(){
		return{
      // Constants
			openseaLink: OPENSEA_LINK,
			twitterLink: TWITTER_LINK,
      discordLink: DISCORD_LINK,
      mintingDate: MINTING_DATE,
			contractAddress: CONTRACT_ADDRESS,			
			maxSupply: MAX_SUPPLY,

      // Provider
      isInfura: false,

      // Contract info
			maxPerWallet: 0,
			totalSupply: 0,
			isPaused: true,
      isAllowlistEnabled: false,

      // Minting variables
			loading: true,
      checkingAllowlist: false,
			mintingText: '',
			processingTx: false,
      mintQuantity: 1,
      mintingCountdownData: {
        days: 0,
        hours: 0,
        minutes: 0
      },

      // User data structure
			user: {
				wallet: null,
				tokens: [],
        minted: 0,
        maxMintAllowlist: 0,
        proof: null
			},

      // Gallery variables
			maxNFts: 103,
			desktopNfts: 24,
			mobileNFts: 15,
			randomizedNfts: [],
			galleryNfts: []
		}
	},
	computed:{
		isConnected: function(){
			return this.user.wallet != null && this.user.wallet != undefined
		},
		isSoldOut: function(){
			return parseInt(this.totalSupply) == parseInt(this.maxSupply)
		},
    isWhitelisted: function(){
      return this.user.proof != null && this.user.proof != undefined
    },
    userMintRemaining: function(){
      return Math.max(0, parseInt(this.maxMint) - parseInt(this.user.minted))
    },
		formattedWallet: function(){
			if (!this.isConnected) return ""
			return `${this.user.wallet.substr(0, 6)}...${this.user.wallet.substr(-4, 4)}`
		}, 
    maxMint: function(){
      if (this.isAllowlistEnabled) return this.user.maxMintAllowlist
      return this.maxPerWallet
    },
    canMint: function(){
      if (this.isSoldOut) return false
      if (this.isAllowlistEnabled) return this.isWhitelisted;
      return !this.isPaused
    },
    zeroCountdown: function(){
      let x = this.mintingCountdownData.days + this.mintingCountdownData.hours + this.mintingCountdownData.minutes
      return x < 1
    },
    mintingCountdown: function(){
      return `${this.mintingCountdownData.days} ${this.mintingCountdownData.hours} ${this.mintingCountdownData.minutes}`
    },
    isAllowlistMintingEnded: function(){
      let minutes = this.mintingCountdownData.minutes + this.mintingCountdownData.hours * 60
      return minutes < 1
    },
    publicMintingCountdown: function(){
      let hours = this.mintingCountdownData.hours
      let minutes = this.mintingCountdownData.minutes
      if (hours > 0) return `${hours} Hour${hours > 1 ? 's' : ''} and ${minutes} Minute${minutes > 1 ? 's' : ''}`
      if (minutes > 0) return `${minutes} Minute${minutes > 1 ? 's' : ''}`
      return 'Holders minting will open in a few seconds'
    },
    mintingDateGMT: function(){
      let localDate = new Date(MINTING_DATE)
      let UTCDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), localDate.getHours(), localDate.getMinutes(), localDate.getSeconds()))

      return '(' + UTCDate.toString().split('(')[1]
    },
    mintingDateText: function(){
      let localDate = new Date(MINTING_DATE)
      let UTCDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), localDate.getHours(), localDate.getMinutes(), localDate.getSeconds()))

      return UTCDate.toLocaleString()
    }
	},
	methods: {

    useSecondaryProvider: async function(){
      if (web3Modal.cachedProvider){

        let provider = null
        try{
          provider = await web3Modal.connect()
          S4.setProvider(provider)
          this.isInfura = false
          console.log("using cached provider")

          return
        }
        catch(e){}
      }

      S4.setProvider(infuraProvider)
      this.isInfura = true
      console.log("using infura provider")
    },

    increase: function(amount){
      this.mintQuantity = Math.max(1, Math.min(this.userMintRemaining, this.mintQuantity + amount))
    },

    decrease: function(amount){
      this.mintQuantity = Math.max(1, this.mintQuantity - amount)
    },

    switchChain: async function (provider){
      return new Promise((resolve, reject) => {

        const web3 = new Web3(provider)
        web3.eth.getChainId()
          .then(chainId => {
            if (chainId == CHAIN_ID) resolve(chainId)
            else{
              provider.request({	method: "wallet_switchEthereumChain", params: [{chainId: CHAIN_ID}]})
                .then(res => resolve(res))
                .catch(error => reject(error))
            }
          })
          .catch(error => reject(error))

      })
    },

    clearWallet: function(){
      this.user.wallet = null
      this.user.tokens = []
      this.user.minted = 0;
      this.user.maxMintAllowlist = 0
      this.user.proof = null
    },

    selectWallet: function(wallet){
      this.clearWallet()
      this.user.wallet = wallet

      // Fetch minted data
      S4._minted(this.user.wallet).then(res => this.user.minted = parseInt(res))

      if (this.isAllowlistEnabled){
        this.checkingAllowlist = true;

        axios.post(WHITELIST_ENDPOINT + '/mint', { wallet: this.user.wallet })
          .then(res => {
            let data = res.data

            this.user.proof = data.proof
            this.user.maxMintAllowlist = data.totalMint
            this.checkingAllowlist = false;
          }, error =>{
            this.user.proof = null
            this.user.maxMintAllowlist = 0
            this.checkingAllowlist = false;
          })
      }
    },

    disconnectWallet: function(){
      window.location.reload()
    },

		connectWallet: async function(){
      web3Modal.clearCachedProvider()
      web3Modal.connect()
        .then(provider => {

          this.switchChain(provider)
            .then(res => {

              if (provider._events.accountsChanged == undefined){
                provider.on("accountsChanged", (accounts) => {
                  if (!this.isConnected) return

                  if (accounts.length == 0){
                    this.disconnectWallet()
                  }
                  else{
                    this.selectWallet(accounts[0])
                  }
                })
              }

              if (provider._events.chainChanged == undefined){
                provider.on("chainChanged", (chainId) => {
                  if (chainId != CHAIN_ID){
                    this.disconnectWallet()
                  }
                });
              }

              if (provider._events.disconnect == undefined){
                provider.on("disconnect", (chainId) => {
                  this.disconnectWallet()
                });
              }

              const web3 = new Web3(provider)
              web3.eth.getAccounts()
                .then(wallets => {
                  S4.setProvider(provider)
                  this.isInfura = false
                  this.selectWallet(wallets[0])
                  console.log("using user provider")
                })
                .catch(error => console.log(error))
        
            })
            .catch(error => this.disconnectWallet())

        })
        .catch(error => {
          console.log(error)

          this.useSecondaryProvider()
        })
		},

		fetchContractData: async function(){
      this.loading = true;
      
      try{
        this.isPaused = await S4.paused()
        this.isAllowlistEnabled = await S4.isAllowlistEnabled()
        if (!this.isAllowlistEnabled){
          this.maxPerWallet = await S4.maxPerMint()
        }
      }
      catch(e){
        this.isPaused = false
        this.isAllowlistEnabled = true
        this.maxPerWallet = 2

        console.log("error on fetching contract data")
      }

      this.loading = false
		},

		updateTotalSupply: function(){
      const func = () => {
        S4.totalSupply().then(res => this.totalSupply = parseInt(res))
        console.log("refreshing total supply")
      }

      func()
			setInterval(() => {
        if (!this.isInfura){
          func()
        }
      }, 10000)
		},

		processTransfer: function(obj){
			this.user.tokens.push(obj.returnValues.tokenId)
		},

    internalMint: async function(quantity){
      return new Promise((resolve, reject) => {
        if (this.isAllowlistEnabled){
          S4.mintWithProof(this.user.wallet, quantity, this.user.maxMintAllowlist, this.user.proof)
            .then(result => resolve(result))
            .catch(error => reject(error))
        }
        else{
          S4.mint(this.user.wallet, quantity)
            .then(result => resolve(result))
            .catch(error => reject(error))
        }
      })
    },

		mint: function(){
			this.mintingText = `Minting ${this.mintQuantity}...`
			this.processingTx = true;

      this.internalMint(this.mintQuantity)
        .then(tx => {
          this.processingTx = false;

          // Processing tx
          let transfer = tx.events.Transfer
          if (Array.isArray(transfer)){
            transfer.forEach(element => {
              this.processTransfer(element)
              this.user.minted++
            });
            this.mintQuantity = 1;
          }
          else{
            this.processTransfer(transfer)
            this.user.minted++
            this.mintQuantity = 1;
          }
        })
        .catch(error => {
          this.processingTx = false;
          console.log(error)
        })
		},

		shuffle: function(array) {
			var copy = [], n = array.length, i;

			while (n) {
				i = Math.floor(Math.random() * n--);
				copy.push(array.splice(i, 1)[0]);
			}

			return copy;
		},

		randomizeGallery: function(){
			this.randomizedNfts = Array.from(Array(this.maxNFts).keys())
			this.randomizedNfts = this.shuffle(this.randomizedNfts)
			this.randomizedNfts = this.randomizedNfts.slice(0, this.desktopNfts)

			this.randomizedNfts.forEach(img => {
				this.galleryNfts.push({
					'data-source': `/imgs/nfts/full/${img+1}.png`,
					'src': `/imgs/nfts/thumbnail/${img+1}.png`,
					'alt': " ",
				})
			})
		},

		showImage: function(index){
			let data = this.galleryNfts
			
			// mobile version
			if (window.screen.width < 768){
				data = data.slice(0, this.mobileNFts)
			}

			this.$viewerApi({
				images: data,
				options: {
					toolbar: false,
					url: 'data-source',
					initialViewIndex: index
				}
			})
		},

    updateMintingDate: function(){
      const func = () => {
        let localDate = new Date(MINTING_DATE)
        let UTCDate = new Date(Date.UTC(localDate.getFullYear(), localDate.getMonth(), localDate.getDate(), localDate.getHours(), localDate.getMinutes(), localDate.getSeconds()))
        
        let now = new Date()
        let diff = new Date(UTCDate - now)
        if (diff.getTime() < 0){
          this.mintingCountdownData.days = 0
          this.mintingCountdownData.hours = 0
          this.mintingCountdownData.minutes = 0
        }
        else{
          this.mintingCountdownData.days = diff.getUTCDate() - 1
          this.mintingCountdownData.hours = diff.getUTCHours()
          this.mintingCountdownData.minutes = diff.getUTCMinutes()
  
          if (diff.getUTCSeconds() > 0) this.mintingCountdownData.minutes++
          if (this.mintingCountdownData.minutes == 60) {this.mintingCountdownData.hours++, this.mintingCountdownData.minutes = 0}
          if (this.mintingCountdownData.hours == 24) {this.mintingCountdownData.days++, this.mintingCountdownData.hours = 0}
        }
      }

      func()
      setInterval(() => func(), 1000)
    }
	},
	mounted: async function(){
    // await this.useSecondaryProvider()

    // this.fetchContractData()
    // this.updateTotalSupply()
    // this.updateMintingDate()

		this.randomizeGallery()
    this.isPaused = true
    this.isAllowlistEnabled = false
    this.totalSupply = MAX_SUPPLY
    this.loading = false
	}
}
</script>


<style scoped>
  .bg-up{
    background-image: url('/imgs/bg_asset_uptop.png');
    background-position-x: center;
    background-repeat: no-repeat;
    background-size: 960px 209px;
    background-position-y: top;
  }
  .bg-middle{
    background-image: url('/imgs/bg_asset_upbottom black.jpg');
    background-position-x: center;
    background-repeat: no-repeat;
    background-position-y: top;
    background-size: 960px 165px;
  }
  .bg-down{
    background-image: url('/imgs/bg_asset_middle2.jpg');
    background-position-x: center;
    background-repeat: no-repeat;
    background-position-y: bottom;
  }
  .footer{
    background-image: url('/imgs/footer.jpg');
    background-position-x: center;
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-size: 848px 317px;
    height: 317px;
  }

  .race-name{
    @apply text-2xl text-black;
  }
  .race-class{
    @apply text-2xl text-primary;
  }

  .metadata td:first-child{
    @apply text-primary text-right pr-5;
  }

  .team-social{
    @apply w-9 md:w-auto;
  }

@responsive{
    .bg-up-stretch{
      background-size: 100% 418px;
    }
    .bg-middle-stretch{
      background-size: 100% 331px;
    }
    .bg-down-stretch{
      background-size: 100% 1056px;
    }
    .footer-desktop{
      background-size: 1697px 635px;
      height: 635px;
    }

    .bg-size-auto{
      background-size:auto;
    }
}

</style>
