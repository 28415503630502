const Contract = require("web3-eth-contract")
const Abi = require("@/assets/abi/contract_abi.json")

const CONTRACT_ADDRESS = process.env.VUE_APP_CONTRACT_ADDRESS

let contract = new Contract(Abi, CONTRACT_ADDRESS)

exports.setProvider = function(provider){
    contract.setProvider(provider)
}

exports.totalSupply = async function(){
	return new Promise((resolve, reject) => {
		contract.methods.totalSupply().call()
			.then((result) => {
				resolve(result)
			})
			.catch(error => {
				reject(error)
			});
	})
}

exports.paused = async function(){
	return new Promise((resolve, reject) => {
		contract.methods.paused().call()
			.then((result) => {
				resolve(result)
			})
			.catch(error => {
				reject(error)
			});
	})
}

exports.maxPerMint = async function(){
	return new Promise((resolve, reject) => {
		contract.methods.maxPerMint().call()
			.then((result) => {
				resolve(parseInt(result))
			})
			.catch(error => {
				reject(error)
			});
	})
}

exports.isAllowlistEnabled = async function(){
	return new Promise((resolve, reject) => {
		contract.methods.isAllowlistEnabled().call()
			.then((result) => {
				resolve(result)
			})
			.catch(error => {
				reject(error)
			});
	})
}

exports._minted = async function(wallet){
	return new Promise((resolve, reject) => {
		contract.methods._minted(wallet).call()
			.then((result) => {
				resolve(result)
			})
			.catch(error => {
				reject(error)
			});
	})
}

exports.balanceOf = async function(wallet){
	return new Promise((resolve, reject) => {
		contract.methods.balanceOf(wallet).call()
			.then((result) => {
				resolve(result)
			})
			.catch(error => {
				reject(error)
			});
	})
}

exports.mint = async function(wallet, amount){
	return new Promise((resolve, reject) => {
		contract.methods.mint(amount).send({from: wallet})
			.then((result) => {
				resolve(result)
			})
			.catch(error => {
				reject(error)
			})
	})
}

exports.mintWithProof = async function(wallet, amount, maxQuantity, proof){
	return new Promise((resolve, reject) => {
		contract.methods.mintWithProof(amount, maxQuantity, proof).send({from: wallet})
			.then((result) => {
				resolve(result)
			})
			.catch(error => {
				reject(error)
			})
	})
}